import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Remote } from '../../utils';
import Upload from '../../Upload'
import ContentsDraftComment from '../../contents/DraftComment'
import SubmissionDraftContentAttachment from '../DraftContentAttachment'
import SubmissionLinkForm from "../LinkForm";

class SubmissionUserGeneratedContent extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedValidate = _.debounce(this.validate, 500);
    this.debouncedAttachments = _.debounce(this.updateAttachments, 500);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.updateCaption = this.updateCaption.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.userGeneratedContentComments = this.userGeneratedContentComments.bind(this);
  }

  componentDidMount() {
    this.props.setUserGeneratedContentAttachments(this.props.bloggerShopTask.user_generated_content_attachments);
    this.debouncedAttachments();
  }

  updateCaption(e) {
    this.props.updateSubmission({ description2: e.target.value });
    this.debouncedValidate();
  }

  updateAttachments() {
    let ids = this.props.userGeneratedContentAttachments.map((attachment) => attachment.id);
    this.props.updateSubmission({user_generated_content_attachment_ids: ids});
    this.debouncedValidate();
  }

  validate() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/validate`
    });

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        type: this.props.contentType.name,
        user_id: this.props.userId,
        description2: this.props.description2
      }
    }).then((response) => {
      this.props.setValidations(response.data);
    });
  }

  userGeneratedContentComments(){
    if(this.props.bloggerShopTask.content && this.props.bloggerShopTask.campaign.draft_dashboard_display === 'draft_review'){
      let comments = _.orderBy(this.props.bloggerShopTask.content.user_generated_content_comments, (value) => new Date(value.created_at), ['desc']);
      return <div>
        <h4>Comment History</h4>
        { this.props.bloggerShopTask.content.user_generated_content_comments && comments.map((comment) =>
          <ContentsDraftComment key={comment.id} comment={comment}/>)
        }
      </div>
    }
    return null;
  }

  renderForm(){
    const { userGeneratedContentAttachments } = this.props;
    const contentCount = userGeneratedContentAttachments ? userGeneratedContentAttachments.length : 0
    const addMediaImagePath = `/contents/create_attachment`

    return (
        <div className='submission draft'>
          {
              contentCount == 0 && <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
                <div className="text-center">Add media files to get started.</div>
                <div className="text-center">Accepted formats include: .jpg, .png, .mov, and .mp4</div>
              </div>
          }

          {!this.props.readOnly && <div style={{paddingBottom: '10px'}}>
            <div>
              <Upload uploadPath={addMediaImagePath}
                      fileParam={"user_generated_content_attachment"}
                      params={{taskId: this.props.bloggerShopTask.id, type: 'user_generated_content_attachment'}}
                      fileTypes={'.jpg, .png, .mov, .mp4'}
                      callback={this.addAttachment}/>
            </div>
          </div>}

          <div className='form-group'>
            <label>Post caption:</label>
            <textarea className="form-control" disabled={this.props.readOnly} value={this.props.description2 || ''}
                      onChange={this.updateCaption} style={{width: '100%', height: '150px'}}></textarea>
          </div>
          {/*<SubmissionLinkForm notMandatory={this.notMandatory}></SubmissionLinkForm>*/}
          {this.userGeneratedContentComments()}
        </div>
    );
  }

  isVideo(fileType) {
    return fileType.includes("video")
  }

  render() {
    const {userGeneratedContentAttachments, contentType, loading} = this.props;
    const contentCount = userGeneratedContentAttachments ? userGeneratedContentAttachments.length : 0

    let type = () => {
      switch (contentType.name) {
      case 'UserGeneratedContent':
        return 'user_generated_content';
      default:
        return '';
      }
    }

    if (loading) {
      return null;
    } else if(userGeneratedContentAttachments && contentCount) {
      return (
        <div>
          <div className={`submission content-grid content-count-${contentCount} ${type()}`}>
            { userGeneratedContentAttachments.map((attachment, index) =>
                <SubmissionDraftContentAttachment key={index} index={index} attachment={attachment}
                                                  deleteAttachment={this.deleteAttachment}
                                                  readOnly={this.props.readOnly}/>) }
          </div>
          {this.renderForm()}
        </div>
      )
    } else {
      return (
        this.renderForm()
      )
    }
  }

  addAttachment(attachment) {
    this.props.addUserGeneratedContentAttachment(attachment);
    this.debouncedAttachments();
  }

  deleteAttachment(attachment) {
    const deleteMediaImagePath = `/contents/destroy_attachment?attachment_id=${attachment.id}`

    Remote().request({
      method: 'POST',
      url: deleteMediaImagePath
    }).then((response) => {
      this.props.deleteUserGeneratedContentAttachment(response.data);
      this.debouncedAttachments();
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      userId: state.meta.userId,
      bloggerShopTask: state.bloggerShopTask,
      contentType: state.contentType,
      proxyUrl: state.meta.proxyUrl,
      userGeneratedContentAttachments: state.userGeneratedContentAttachments,
      description2: state.submission.description2,
      readOnly: state.readOnly,
      loading: state.loading
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addUserGeneratedContentAttachment: attachment => dispatch({ type: 'ADD_USER_GENERATED_CONTENT_ATTACHMENT', value: attachment}),
      deleteUserGeneratedContentAttachment: attachment => dispatch({ type: 'DELETE_USER_GENERATED_CONTENT_ATTACHMENT', value: attachment}),
      setUserGeneratedContentAttachments: attachments => dispatch({ type: 'SET_USER_GENERATED_CONTENT_ATTACHMENTS', value: attachments}),
      setValidations: validations => dispatch({ type: 'SET_VALIDATIONS', value: validations }),
      updateSubmission: change => dispatch({ type: 'UPDATE_SUBMISSION', value: change}),
      setLoading: loading => dispatch({ type: 'SET_LOADING', value: loading })
    };
  }
};

SubmissionUserGeneratedContent.displayName = 'Submission.UserGeneratedContent';
export default connect(
  SubmissionUserGeneratedContent.mapStateToProps,
  SubmissionUserGeneratedContent.mapDispatchToProps
)(SubmissionUserGeneratedContent);
