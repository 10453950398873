import React from 'react';
import axios from 'axios';
import { isAscii } from '../utils'
import AuxillaryContentsFacebookVideo from './FacebookVideo';
import AuxillaryContentsInstagramPost from './InstagramPost';
import AuxillaryContentsInstagramReel from './InstagramReel';
import AuxillaryContentsInstagramStory from './InstagramStory';
import AuxillaryContentsYoutubeVideo from './YoutubeVideo';
import AuxillaryContentsYoutubeShort from './YoutubeShort';
import AuxillaryContentsBlogPost from './BlogPost';
import AuxillaryContentsTiktokVideo from './TiktokVideo';
import AuxillaryContentsPinterestPost from "./PinterestPost";
import AuxillaryContentsUserGeneratedContent from "./UserGeneratedContent";

import {
  regexInstagramStory,
  regexInstagramPost,
  regexInstagramReelAlt,
  regexNoSocialMedia,
  regexYoutubeVideoRegex,
  regexYoutubeShort,
  regexFacebookVideoAlt,
  regexTiktokManual,
  regexPinterestManual,
  phYoutubeVideo,
  phYoutubeShort,
  phTiktookVideoAlt,
  phFacebookVideoAlt,
  phInstagramReel,
  phInstagramPost,
  phInstagramStory,
  phPinterestPost,
} from "../lib/submission";

function Required() {
  return <span style={{ color: "red", fontWeight: "bold" }}>*</span>;
}

export default class AuxillaryContentsEdit extends React.Component {
  constructor(props) {
    super(props);
    const { content_meta, url, username, id, title } = props.content || {};
    const { shoppertunities } = props;
    const meta = content_meta || {};
    this.state = {
      first_name: (username || "").split(" ")[0],
      last_name: (username || "").split(" ").slice(1).join(" "),
      url: url || "",
      type: meta.type || "TiktokVideo",
      post_type: meta.post_type || "",
      contentMeta: content_meta || this.contentMap().YoutubeVideo || this.contentMap().YoutubeShort,
      title: meta.title || title,
      getPost: !!props.disabled,
      loading: false,
      error: false,
      errorMessage: "",
      id: id || "",
      shoppertunity_id: shoppertunities[0][0] || "",
      blogImage: null,
    };
    this.setValue = this.setValue.bind(this);
    this.changeType = this.changeType.bind(this);
    this.setPostType = this.setPostType.bind(this);
    this.changeIGPostType = this.changeIGPostType.bind(this);
    this.getPostData = this.getPostData.bind(this);
    this.fetchPostDetails = this.fetchPostDetails.bind(this);
    this.getBlob = this.getBlob.bind(this);
    this.setContentMetaValue = this.setContentMetaValue.bind(this);
    this.save = this.save.bind(this);
    this.getContentForm = this.getContentForm.bind(this);
  }

  componentDidMount() {
    const {
      content: { content_meta, url },
      disabled,
    } = this.props;
    const meta = content_meta || {};
    if (
      !!url &&
      this.validUrl(url, meta.type || "YoutubeVideo" || "YoutubeShort") &&
      !!disabled &&
      !content_meta
    ) {
      this.getPostData();
    }
  }

  validRegexes() {
    return {
      InstagramStory: regexInstagramStory,
      InstagramPost: regexInstagramPost,
      InstagramReel: regexInstagramReelAlt,
      BlogPost: regexNoSocialMedia,
      YoutubeVideo: regexYoutubeVideoRegex,
      YoutubeShort: regexYoutubeShort,
      FacebookVideo: regexFacebookVideoAlt,
      TiktokVideo: regexTiktokManual,
      PinterestPost: regexPinterestManual,
    };
  }

  storyObject() {
    return {
      likes: 0,
      image_url: "",
      views: 0,
    };
  }

  tikTokObject() {
    return {
      views: 0,
      likes: 0,
      comments: 0,
      shares: 0,
    };
  }

  contentMap() {
    const commonTemplate = {
      image_url: "",
      views: 0,
      comments: 0,
    };
    return {
      InstagramStory: {
        media: [],
        followers: 0,
      },
      InstagramPost: {
        ...commonTemplate,
        likes: 0,
        followers: 0,
        username: null,
        oembed_html: null,
      },
      InstagramReel: {
        ...commonTemplate,
        likes: 0,
        followers: 0,
        username: null,
        oembed_html: null,
      },
      BlogPost: { ...commonTemplate },
      YoutubeVideo: {
        ...commonTemplate,
        likes: 0,
      },
      YoutubeShort: {
        ...commonTemplate,
        likes: 0,
      },
      FacebookVideo: {
        ...commonTemplate,
        likes: 0,
      },
      TiktokVideo: {
        handle: null,
        oembed_html: null,
        media: [
          { views: 0, likes: 0, shares: 0, comments: 0, image_url: null },
        ],
      },
      PinterestPost: {
        ...commonTemplate,
        re_pins: 0,
        reactions: 0,
        followers: 0
      },
      UserGeneratedContent: {
        media: [],
        description: ''
      },
    };
  }

  placeHolderMap(contentType) {
    const urlMap = {
      InstagramStory: phInstagramStory,
      InstagramPost: phInstagramPost,
      InstagramReel: phInstagramReel,
      FacebookVideo: phFacebookVideoAlt,
      BlogPost: "Blog Post Url",
      YoutubeVideo: phYoutubeVideo,
      YoutubeShort: phYoutubeShort,
      TiktokVideo: phTiktookVideoAlt,
      PinterestPost: phPinterestPost
    };

    return urlMap[contentType] || "";
  }

  baseData(contentType) {
    return this.contentMap()[contentType] || {};
  }

  flexStyles(justification, columnDirection) {
    return {
      display: "flex",
      justifyContent: !!justification ? justification : "space-between",
      alignItems: "center",
      flexDirection: !!columnDirection ? "column" : "row",
      flexWrap: "wrap",
    };
  }

  hightlightHashTags(content) {
    return (content || "").replace(/([#@][\w]+)/g, '<a href="#">$1</a>');
  }

  renderOptions() {
    const optionData = [
      "Instagram Story",
      "Instagram Post",
      "Instagram Reel",
      "Blog Post",
      "Youtube Video",
      "Youtube Short",
      "Facebook Video",
      "TikTok Video",
      "Pinterest Post",
      "User Generated Content"
    ];
    return optionData.map((opt, i) => (
      <option key={`contentOption${i}`} value={this.formatType(opt)}>
        {opt}
      </option>
    ));
  }

  renderPostTypeOptions() {
    const optionData = ["Image", "Video", "Carousel Album"];
    return optionData.map((opt, i) => {
      //to match post_type values for cthulhu
      let option_value = opt != "Carousel Album" ? opt : "Side Car";
      return (
        <option
          key={`contentOption${i}`}
          value={option_value.replace(/ /g, "_").toLocaleLowerCase()}
        >
          {opt}
        </option>
      );
    });
  }

  formatType(type) {
    //this formats the type so that we can display social platforms with
    //proper capitalizations while storing proper type casing for cthulhu
    return type
      .split(" ")
      .map((v) => v[0].charAt(0).toUpperCase() + v.slice(1).toLowerCase())
      .join("");
  }

  renderShoppertunities(shoppertunities) {
    return shoppertunities.map((shop) => (
      <option key={shop[0]} value={shop[0]}>
        {shop[1]}
      </option>
    ));
  }

  changeType(event) {
    if (!this.props.disabled) {
      this.setValue("type", event.target.value);
      let post_type = this.setPostType(event.target.value);
      this.setValue("post_type", post_type);
      this.setState({
        contentMeta: this.baseData(event.target.value),
        getPost: false,
      });
    }
  }

  setPostType(type) {
    switch (type) {
      case "InstagramPost":
        return "image"; //initial value
      case "InstagramReel":
        return "video";
      default:
        return "";
    }
  }

  changeIGPostType(event) {
    if (!this.props.disabled) {
      this.setValue("post_type", event.target.value);
      this.setContentMetaValue("post_type", event.target.value);
    }
  }

  setValue(field, value, callback) {
    this.setState({ [field]: value }, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
    if (field === "url") {
      const { type } = this.state;
      this.setState({ contentMeta: this.baseData(type), getPost: false });
    }
  }

  setContentMetaValue(field, value, callback) {
    this.setValue(
      "contentMeta",
      { ...this.state.contentMeta, [field]: value },
      callback
    );
  }

  save() {
    this.setState({ saving: true });
    const {
      contentMeta: {
        likes,
        views,
        comments,
        shares,
        saves,
        title,
        caption,
        description,
        image_url,
        media,
        post_type,
        handle,
        followers,
      },
      url,
      first_name,
      last_name,
      type,
      id,
      shoppertunity_id,
    } = this.state;
    const params = {
      url,
      first_name,
      last_name,
      likes,
      comments,
      views,
      shares,
      saves,
      handle,
      title,
      caption,
      description,
      type,
      image_url,
      media,
      id,
      post_type,
      shoppertunity_id,
      followers,
    };

    axios
      .post(this.props.save_url, params)
      .then((res) => {
        this.setState({ saving: false });
        window.location = res.data.redirect_url;
      })
      .catch((error) => {
        const { message, status } = error.response.data;
        const errorMessage =
          status === 500
            ? "500 Internal server error"
            : (message || []).join(", ");
        this.setState({ error: true, errorMessage, saving: false });
      });
  }

  validUrl(url, type) {
    const regex = this.validRegexes()[type];
    if (type === "BlogPost") {
      return regex.test(url) && isAscii(url);
    } else {
      return regex.test(url);
    }
  }

  getPostData(_event) {
    const { url, type, first_name } = this.state;
    if (["TiktokVideo", "InstagramPost", "InstagramReel"].includes(type)) {
      this.setState({ getPost: true, loading: false });
    } else {
      this.fetchPostDetails(url, type, first_name);
    }
  }

  fetchPostDetails(url, type, first_name) {
    this.setState({ loading: true, error: false, errorMessage: "" });
    const { details_url } = this.props;
    if (!!url && this.validUrl(url, type)) {
      axios
        .get(details_url, { params: { url: url } })
        .then((response) => {
          let error = false;
          let errorMessage = "";
          if (!first_name || !url) {
            error = true;
            errorMessage = "Please fill in required fields";
          }
          this.setState({
            error,
            errorMessage,
            getPost: true,
            contentMeta: response.data,
            loading: false,
          });
        })
        .catch((error) => {
          const { message } = error.response.data;
          this.setState({ loading: false, error: true, errorMessage: message });
        });
    }
  }

  getBlob(url) {
    axios
      .get(this.props.blob_url, { params: { url: url } })
      .then((response) => {
        console.log(response.data);
        this.setState({ blobImage: response.data.blob });
      });
  }

  getContentForm(type) {
    switch (type) {
      case "FacebookVideo":
        return AuxillaryContentsFacebookVideo;
      case "InstagramStory":
        return AuxillaryContentsInstagramStory;
      case "InstagramPost":
        return AuxillaryContentsInstagramPost;
      case "InstagramReel":
        return AuxillaryContentsInstagramReel;
      case "BlogPost":
        return AuxillaryContentsBlogPost;
      case "YoutubeVideo":
        return AuxillaryContentsYoutubeVideo;
      case "YoutubeShort":
        return AuxillaryContentsYoutubeShort;
      case "TiktokVideo":
        return AuxillaryContentsTiktokVideo;
      case "PinterestPost":
        return AuxillaryContentsPinterestPost;
      case "UserGeneratedContent":
        return AuxillaryContentsUserGeneratedContent;
      default:
        return AuxillaryContentsYoutubeVideo;
    }
  }

  isInstagramPost() {
    return this.state.type === "InstagramPost";
  }

  render() {
    const { disabled, upload_url, proxyUrl, shoppertunities } = this.props;
    const {
      first_name,
      last_name,
      type,
      url,
      contentMeta,
      getPost,
      loading,
      error,
      errorMessage,
      shoppertunity_id,
      blobImage,
      post_type,
    } = this.state;
    const ContentForm = this.getContentForm(type);
    const emptyStory = this.storyObject();
    const isStory = type === "InstagramStory";
    const placeholder = this.placeHolderMap(type);
    const isValidUrl = !!url ? !!url && this.validUrl(url, type) : true;
    const btnDisabled = isStory || !url || !isValidUrl || loading;
    const isUGC = type === "UserGeneratedContent";
    const isValidContent = isUGC ? first_name : (first_name && !!url && isValidUrl);

    return (
      <div className="col-md-12" id="aux_content_edit_form">
        <h3>{disabled ? "Edit" : "Add"} Campaign Content</h3>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="contentType">Content Type</label>
              <select
                id="contentType"
                value={type}
                disabled={disabled}
                required
                className="form-control"
                onChange={(e) => this.changeType(e)}
              >
                <option disabled value>
                  {" "}
                  -- select an option --{" "}
                </option>
                {this.renderOptions()}
              </select>
            </div>
          </div>
          {this.isInstagramPost() && (
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="postType">Post Type</label>
                <select
                  id="postType"
                  value={post_type}
                  disabled={disabled}
                  required
                  className="form-control"
                  onChange={this.changeIGPostType}
                >
                  <option disabled value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {this.renderPostTypeOptions()}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="firstName">
                Influencer First Name
                <Required />
              </label>
              <input
                type="text"
                id="firstName"
                disabled={disabled}
                value={first_name}
                onChange={(event) => {
                  this.setValue("first_name", event.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                disabled={disabled}
                value={last_name}
                onChange={(event) => {
                  this.setValue("last_name", event.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="shoppertunity">Opportunity</label>
            <select
              id="shoppertunity"
              disabled={disabled}
              required
              className="form-control"
              onChange={(event) => {
                this.setValue("shoppertunity_id", event.target.value);
              }}
            >
              <option value={shoppertunity_id} disabled>
                {" "}
                -- select an option --{" "}
              </option>
              {this.renderShoppertunities(shoppertunities)}
            </select>
          </div>
        </div>
        <br />
        {isUGC ? '' : <div className="row">
          <div className="col-md-10">
            <div
              className={`form-group ${
                !!url && !isValidUrl ? "has-error" : ""
              }`}
            >
              <label htmlFor="url">
                {isStory ? "Instagram Profile URL" : "Content URL"}
                <Required />
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="url"
                  placeholder={placeholder}
                  disabled={disabled}
                  value={url}
                  onChange={(event) => {
                    this.setValue("url", event.target.value);
                  }}
                  className="form-control"
                />
                <span className="input-group-btn">
                  <button
                    className="btn btn-primary"
                    disabled={btnDisabled || disabled}
                    onClick={this.getPostData}
                  >
                    Get Post&nbsp;
                    {loading && <i className="fas fa-circle-notch fa-spin"></i>}
                  </button>
                </span>
              </div>
            </div>
            {error && <div className="alert alert-danger">{errorMessage}</div>}
          </div>
        </div> }
        {isValidContent && (getPost || isStory || isUGC) && (
          <div className="row">
            <div className="col-md-10" style={{ marginTop: 10 }}>
              {
                <ContentForm
                  setValue={this.setValue}
                  flexStyles={this.flexStyles}
                  getBlob={this.getBlob}
                  hightlightHashTags={this.hightlightHashTags}
                  type={type}
                  post_type={post_type}
                  url={url}
                  shoppertunity_id={shoppertunity_id}
                  storyObject={emptyStory}
                  onSave={this.save}
                  upload_url={upload_url}
                  disabled={disabled}
                  blogImage={blobImage}
                  proxyUrl={proxyUrl}
                  {...contentMeta}
                />
              }
            </div>
          </div>
        )}
      </div>
    );
  }
}

AuxillaryContentsEdit.displayName = 'AuxillaryContents.Edit'