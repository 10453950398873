const InfluencerDashboardContentTypes = {
  FacebookContent: 'Facebook Post',
  BlogPostContent: 'Blog Post',
  TweetContent: 'Twitter Post',
  PinterestContent: 'Pinterest Post',
  InstagramContent: 'Instagram Post',
  InstagramDraftContent: 'Instagram Draft',
  LegacyDraftContent: 'Legacy Post',
  OtherContent: 'Other Content',
  DraftContent: 'Blog Draft',
  InstagramBusinessStoryContent: 'Instagram Story',
  InstagramStoryContent: 'Instagram Story',
  ShortFormVideoContent: 'Short Video',
  FacebookVideoContent: 'Facebook Video',
  YoutubeContent: 'Youtube Video',
  LongFormVideoContent: 'Long Video',
  UserGeneratedContent: 'User Generated Content'
};
export default InfluencerDashboardContentTypes;