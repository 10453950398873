import React from 'react';

export default class UserGeneratedContent extends React.Component {
  render(){
    const {activity, user_name, shoppertunity_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'accepted':
        return (
          <P>
            Your User Generated Content for {shoppertunity_name} has been approved!
          </P>
        )
      case 'rejected':
        return (
          <P>Your User Generated Content for <b>{shoppertunity_name}</b> has been rejected.</P>
        )
      default:
        return null;
    }
  }
}
UserGeneratedContent.displayName = 'Notification.Templates.UserGeneratedContent'