import React from 'react';
import { FileUploader } from '../utils';

import dropDownStyles from './styles'

function Required() {
  return <span style={{color: 'red', fontWeight: 'bold'}}>*</span>
}

export default class AuxillaryContentsUserGeneratedContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.mapProps(props || []),
      autoUpload: !!JSON.parse(localStorage.getItem('auxillary-content-auto-upload') || 'null')
    }
    this.onFileSelect = this.onFileSelect.bind(this);
    this.upload = this.upload.bind(this);
    this.save = this.save.bind(this);
    this.saveInput = this.saveInput.bind(this);

    this.debouncedUpdate = _.debounce(this.saveInput, 500);
    this.setValue = this.setValue.bind(this);
  }
  saveVal(type, value) {
    if (!!value && !isNaN(parseInt(value))) {
      this.setState({ [type]: parseInt(value) })
    }
  }

  mapProps(props) {
    const cprops = {...props};
    cprops.media = (cprops.media || []);
    cprops.media = cprops.media.map((media) => ({
      ...media,
      uploaded: true,
      deleted: false,
      uploading: false,
      largeFile: false,
      file: null,
      previewURL: media.image_url || null,
      ref: React.createRef()
    }));
    return cprops
  }

  demappedMedia(media) {
    return media.filter(content => (!content.deleted && content.image_url)).map(content => ({
      image_url: content.image_url,
      media_type: content.media_type,
      description: content.description
    }))
  }

  toggleAutoUpload() {
    const {autoUpload} = this.state;
    localStorage.setItem('auxillary-content-auto-upload', JSON.stringify(!autoUpload));
    this.setState({autoUpload: !autoUpload});
    if (!autoUpload) {
      this.uploadPendingUploads()
    }
  }

  upload(index, file) {
    const {media, upload_url} = this.state;
    media[index].uploading = true;
    if (file.type.startsWith('video')) {
      media[index].media_type = 'video'
    } else {
      media[index].media_type = 'image'
    }
    this.setState({media})
    const formData = new FormData();
    formData.append('file', file);
    FileUploader().request({
      url: upload_url,
      method: 'POST',
      data: formData,
    }).then((response, textStatus, jXHR) => {
      media[index].uploading = false;
      media[index].uploaded = true;
      media[index].largeFile = false;
      media[index].image_url = response.data.image_url;
      media[index].previewURL = response.data.image_url;
      this.setState({ media })
    }).catch((jXHR, textStatus, error) => {
      console.log(jXHR);
      console.log(textStatus);
    })
  }

  delete(index) {
    const {media} = this.state;
    if (!media[index].uploading) {
      media[index].deleted = true
      this.setState({media});
    }
  }
  undoDelete(index) {
    const {media} = this.state;
    media[index].deleted = false
    this.setState({media});
  }
  addNew() {
    const {contentObject} = this.props;
    const {media} = this.state;
    media.push({...contentObject,
      uploaded: false,
      deleted: false,
      uploading: false,
      file: null,
      previewURL: null,
      largeFile: false,
      ref: React.createRef()
    });
    this.setState({media})
  }
  onFileSelect(index, file) {
    const {media, autoUpload} = this.state;
    media[index].file = file;
    media[index].uploaded = false;
    media[index].uploading = false;
    media[index].largeFile = false;
    media[index].previewURL = URL.createObjectURL(file);
    if(autoUpload) {
      this.upload(index, file);
    }
    this.setState({media});
    this.generatePreview(index, file);
  }

  setValue(field, value, callback) {
    this.setState({ [field]: value }, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
    if (field === "url") {
      const { type } = this.state;
      this.setState({ contentMeta: this.baseData(type), getPost: false });
    }
  }

  uploadPendingUploads() {
    const {media} = this.state;
    media.forEach((content, index) => {
      if(!content.uploaded && !content.deleted && content.file) {
        this.upload(index, content.file);
      }
    })
  }

  changeDescription(index, text) {
    const {media} = this.state;
    media[index].description = text;
    this.setState({media});
  }

  changeFile(index) {
    const content = this.state.media[index]
    content.ref.current.click()
  }
  generatePreview(index, file) {
    const that = this;
    const {media} = that.state;
    if (file.size > 10000000) {
      media[index].largeFile = true;
      that.setState({media})
      return;
    }
    const reader = new FileReader();
    reader.onload = function(event) {
      media[index].previewURL = event.target.result;
      that.setState({media})
    }
    reader.readAsDataURL(file)
  }

  save() {
    const {media, description} = this.state;
    const {setValue, onSave} = this.props;
    const hasPendingUploads = !!media.find(m => !!(!m.uploaded && !m.deleted && m.file));
    if (hasPendingUploads) {
      this.uploadPendingUploads();
      setTimeout(() => {this.save()}, 500);
    } else {
      setValue('contentMeta', {description: description, media: this.demappedMedia(media)}, onSave)
    }
  }

  saveInput(prop, value) {
    if(!!value && !isNaN(parseInt(value))) {
      this.setState({ [prop]: parseInt(value) })
    }
  }

  renderMenuItems(content, index) {
    const {uploaded,deleted, uploading} = content
    const edit_page = window.location.pathname.endsWith('/edit')
    return (
        <Overlay variant={deleted ? 'danger' : uploading ? 'primary' : 'transparent'}>
          { !edit_page && <Topbar>
            <div tabIndex={`${index}story0`} disabled={uploading || deleted} className="topbar-item contains-dropdown">
              <i title="Options" className="fas fa-bars"></i>
              {(!deleted && !uploading) && <ul className="aux-dropdown">
                <li className="aux-dropdown-item"><span onClick={(_e) => {
                  this.changeFile(index)
                }}>Change File</span></li>
              </ul>}
            </div>
            {/* upload button */}
            {(!uploaded && !deleted) && <div title="Upload Attachment" className="topbar-item" onClick={(_e) => {
              if (!uploading) {
                this.upload(index, content.file)
              }
            }}>
              {!uploading && (<i className="fas fa-cloud-upload-alt text-success"></i>)}
              {uploading && (<i className="fas fa-circle-notch fa-spin text-success"></i>)}
            </div>}
            {/* delete button */}
            {(!deleted) && <div title={uploading ? 'Cannot delete while uploading' : 'Delete'} className="topbar-item"
                                onClick={(_e) => {
                                  this.delete(index)
                                }}>
              <i className="fas fa-trash text-danger"></i>
            </div>}
            {/* undo delete button */}
            {deleted && <div title="Undo Delete" className="topbar-item" onClick={(_e) => {
              this.undoDelete(index)
            }}>
              <i className="fas fa-undo text-info"></i>
            </div>}
          </Topbar> }
        </Overlay>
    )
  }

  render() {
    const {first_name, description, media, flexStyles, autoUpload} = this.state;
    const nonDeleted = media.filter(m => (!m.deleted && (m.file || m.image_url))).length;
    const imageCard = {
      width: 200,
      margin: '0 5px',
      minHeight: 370,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
    const flexContainer = flexStyles('flex-start');
    const edit_page = window.location.pathname.endsWith('/edit')
    return (
        <React.Fragment>
          <div className="form-group">
            <label htmlFor="aux_ugc_description"
                   style={{margin: '-4px 0 8px', display: 'inline-block'}}>Description</label>
            <textarea id="aux_ugc_description" defaultValue={description} onChange={(event) => {
              this.setValue("description", event.target.value);
            }} className="form-control" disabled={edit_page}/>
          </div>
          <div style={{
            width: '100%',
            paddingBottom: 15,
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
          }}>
            <style dangerouslySetInnerHTML={{__html: dropDownStyles}}></style>
            <div className="container-fluid">
              {edit_page && <div className="text-right" style={{width: '100%'}}>
                <div className="checkbox"><label htmlFor="autoUpload"><input type="checkbox" name="autoUpload"
                                                                             id="autoUpload" checked={autoUpload}
                                                                             onChange={(_e) => {
                                                                               this.toggleAutoUpload()
                                                                             }}/>Auto Upload Attachments</label></div>
                <hr/>
              </div>}
              <div style={{...flexContainer, minHeight: 370}}>
                {
                  media.map((content, index) => (
                      <div key={`story0${index}`} style={imageCard}>
                        <FileField onChange={(event) => {
                          this.onFileSelect(index, event.target.files[0])
                        }} elementRef={content.ref} hidden={(!!content.file || content.uploaded)}/>
                        {(!!content.previewURL || content.uploaded) && (!content.largeFile) &&
                            <div style={{position: 'relative', width: '100%'}}>
                              {this.renderMenuItems(content, index)}
                              <embed src={content.previewURL || content.image_url} style={{width: '100%'}}/>
                            </div>}
                        {content.largeFile && (<div style={{position: 'relative', width: '100%'}}>
                              {this.renderMenuItems(content, index)}
                              <LargeFilePreview filename={content.file.name} size={content.file.size}/>
                            </div>
                        )}
                      </div>
                  ))
                }
                <div style={imageCard}><AddNewCard onClick={(_event) => {
                  this.addNew()
                }}/></div>
              </div>
              <div className="text-right" style={{width: '100%'}}>
                <hr/>
                <button className="btn btn-primary" onClick={this.save} disabled={(nonDeleted < 1) || edit_page}>Save
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
  }
}

AuxillaryContentsUserGeneratedContent.displayName = 'AuxillaryContents.UserGeneratedContent';

function FileField({onChange, elementRef, hidden}) {

  const containerStyle = {
    display: hidden ? 'none' : 'flex', flexDirection: 'column', width: '100%',
    justifyContent: 'center', alignItems: 'center',
    border: '2px dotted #64B5F6', borderRadius: '5px',
    textAlign: 'center', cursor: 'pointer',
    color: '#0D47A1', height: 356,
  }
  const clickTrigger = (_event) => {
    elementRef.current.click();
  }
  return (
    <div className="aux-file" style={containerStyle} onClick={clickTrigger}>
      <span style={{fontSize: '32px'}}><i className="fas fa-cloud-upload-alt"></i></span>
      <span style={{fontSize: '20px'}}>Choose a File..</span>
      <input type="file" ref={elementRef} onChange={onChange} style={{display: 'none'}} accept="image/*,video/*" />
    </div>
  )
}

function AddNewCard({onClick}) {
  const containerStyle = {
    display: 'flex', flexDirection: 'column', width: '100%',
    justifyContent: 'center', alignItems: 'center',
    border: '2px dotted #81C784', borderRadius: '5px',
    textAlign: 'center', cursor: 'pointer',
    color: '#2E7D32', height: 356
  }
  return (
    <div style={containerStyle} onClick={onClick}>
      <span style={{fontSize: '32px'}}><i className="fas fa-plus"></i></span>
      <span style={{fontSize: '20px'}}>Add New Content</span>
    </div>
  )
}

function LargeFilePreview({ filename, size }) {
  const containerStyle = {
    display: 'flex', flexDirection: 'column', width: '100%',
    justifyContent: 'center', alignItems: 'center',
    border: '2px dotted #283593', borderRadius: '5px',
    textAlign: 'center', cursor: 'pointer',
    color: '#283593', height: 356
  }

  const sizeMB = Math.round(size/1000000);
  return (
    <div style={containerStyle}>
      <span style={{ fontSize: '32px' }}><i className="fas fa-file"></i></span>
      <span style={{ fontSize: '20px' }}>{filename}</span>
      <span style={{ fontSize: '18px' }}>{sizeMB}MB</span>
      <span style={{ fontSize: '11px' }}>files with size greater than 10MB can be previewed after uploading</span>
    </div>
  )
}

function Overlay({variant, children}) {
  const variantMap = {
    danger: 'rgba(199, 65, 60, 0.4)',
    success: 'rgba(46, 125, 50, 0.4)',
    primary: 'rgba(24, 123, 209, 0.4)',
    transparent: 'rgba(0,0,0,0.1)'
  }
  const styles = {
    display: 'flex', position: 'absolute',
    width: '100%', flexDirection: 'column',
    top: 0, left: 0, height: '100%', zIndex: '1',
    background: variantMap[variant] || variantMap['primary'], color: 'white',
    justifyContent: 'space-around', alignItems: 'center'
  }

  return (
    <div style={styles}>
      {children}
    </div>
  )
}

function Topbar({children}) {
  const styles = {
    display: 'flex', position: 'absolute',
    top: 10, right: 10, width: 'auto', flexDirection: 'row-reverse',
    alignItems: 'center', width: 'calc(100% - 10px)'
  }
  return (
    <div style={styles}>
      {children}
    </div>
  )
}