import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Remote } from '../utils';
import SubmissionRemoveButton from './RemoveButton'

class SubmissionSubmitButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false
    }

    this.buttonClass = this.buttonClass.bind(this);
    this.buttonText = this.buttonText.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.draftContent = this.draftContent.bind(this);
    this.render = this.render.bind(this);
    this.submissionText = this.submissionText.bind(this);
    this.submit = this.submit.bind(this);
    this.manualSubmission = this.manualSubmission.bind(this);
    this.taskFailure = this.taskFailure.bind(this);
    this.rejected = this.rejected.bind(this);
    this.requirementValidation = this.requirementValidation.bind(this);
    this.hasInstagramAttachements = this.hasInstagramAttachements.bind(this);
    this.validFacebookVideoUrl = this.validFacebookVideoUrl.bind(this);
    this.displayRemoveBtn = this.displayRemoveBtn.bind(this);
  }

  render() {
    const {submitting} = this.state;
    return <div className='submit-container'>
      <span>{this.submissionText()}</span>
      <div className='btn-container'>
        { this.displayRemoveBtn() &&
          <SubmissionRemoveButton />
        }
        <button onClick={this.submit} className={this.buttonClass()} disabled={!this.canSubmit() || submitting }>
          {this.buttonText()}
        </button>`
      </div>
    </div>;
  }

  rejected(){
    const { content } = this.props;

    if (!content)
      return false;

    const rejectedStates = [
      'rejected',
      'reject_new',
      'reject_edit'
    ];

    const validation = content && this.props.content.latest_content_validation;
    if(_.some(rejectedStates, state => this.props.content.state === state)) {
      return true;
    }

    return false;
  }

  submissionText() {
    if(this.props.content && this.rejected()){
      return 'Please review your Content Requirements and any additional feedback.';
    }

    if(this.props.readOnly) {
      switch(this.props.content.state) {
      case 'accepted':
        return 'This task has been approved! No further action is required.';
      case 'pending_client_approval':
        return 'This task is pending. You will be notified once the client has graded your content.';
      case 'pending':
      default:
        return 'This task is pending. You will be notified once a CM has graded your content.';
      }
    }

    if(this.taskFailure()) {
      return `
        This post does not seem eligible. If you believe that this is incorrect,
        you can request to have the post manually reviewed.
      `;
    }

    if(this.canSubmit()) {
      return 'Tap submit to continue...';
    }

    if(this.draftContent()){
      return 'Add media and a caption...';
    }


    return 'Select an eligible post...';
  }

  buttonText() {
    if(this.rejected())
      return this.props.readOnly ? 'Edit Submission' : 'Re-Submit';

    return 'Submit';
  }

  taskFailure() {
    return _.some(this.props.validations, val => val.state == 'failed');
  }

  hasInstagramAttachements(){
    if(this.props.bloggerShopTask.instagram_views){
      return this.props.instagramAttachments.length != 0;
    }else {
      return true;
    }
  }

  validFacebookVideoUrl() {
    const url = this.props.submission.url;
    return url.match(/facebook.com\/(.+?)\/videos\/(.+?)/);
  }

  requirementValidation(){
    return(
      (this.props.bloggerShopTask &&
      this.props.bloggerShopTask.round_task.round_task_requirements.length == 0) ||
      this.props.validations.length > 0
    );
  }

  canSubmit() {
    // Edge case -- make the button clickable to "enable" the form on rejection
    if(this.props.readOnly && this.rejected()) {
      return true;
    } else if(this.props.contentType.name == 'InstagramDraftContent') {
      return this.props.submission &&
        this.requirementValidation() &&
        this.props.instagramAttachments.length > 0 &&
        this.props.readOnly === false;
    } else if(this.props.contentType.name == 'TiktokDraftContent') {
      return this.props.submission &&
        this.requirementValidation() &&
        this.props.tiktokAttachment &&
        this.props.readOnly === false;
    } else if(this.props.contentType.name == 'UserGeneratedContent') {
      return this.props.submission &&
        this.requirementValidation() &&
        this.props.userGeneratedContentAttachments.length > 0 &&
        this.props.readOnly === false;
    } else if(this.props.contentType.name == 'InstagramBusinessStoryContent') {
      return this.props.submission && this.props.readOnly === false && ( this.props.selected.length > 0 || this.props.instagramMediaAttachments.length > 0);
    } else if(this.props.manual) {
      if(this.props.contentType.name == 'InstagramContent') {
        return this.props.submission &&
          this.props.submission.url &&
          this.hasInstagramAttachements() &&
          !_.isEqual(this.props.preview, {});
      }
      if(this.props.contentType.name == 'FacebookVideoContent') {
        return this.props.submission &&
          this.props.submission.url &&
          this.validFacebookVideoUrl()
      }
      if(this.props.contentType.name == 'TiktokVideoContent') {
        return this.props.submission &&
          this.props.submission.url &&
          this.props.submission.html;
      }
      return true;
    } else if(this.manualSubmission()) {
      return true;
    } else if(this.props.contentType.name == 'DraftContent') {
      return this.props.submission &&
        _.get(this.props.submission, 'draft.body') &&
        this.props.submission.title &&
        this.requirementValidation() &&
        this.props.readOnly === false;
    } else {
      return this.props.submission &&
        this.props.submission.url &&
        this.requirementValidation() &&
        this.props.readOnly === false;
    }
  }

  manualSubmission(){
    return ['InstagramContent',
            'YoutubeContent',
            'YoutubeShortContent',
            'FacebookVideoContent'].includes(this.props.contentType.name) &&
            this.props.contentUrl;
  }

  draftContent() {
    return this.props.contentType.name && this.props.contentType.name.toLowerCase().includes("draft");
  }

  buttonClass() {

    if(this.taskFailure() || (this.rejected() && this.props.readOnly)) {
      return 'btn warning';
    }

    return 'btn primary';
  }

  submit() {
    if(!this.canSubmit()) {
      return null;
    }

    this.setState({submitting: true})

    if(this.props.readOnly && this.rejected()) {
      this.props.setReadOnly(false);
      this.props.clearValidations();
      if(this.props.bloggerShopTask.round_task.content_type_ids.length > 1){
        this.props.setContentType({});
      }
      this.setState({submitting: false})
      return null;
    }

    const {
      preview,
      contentType,
      selected,
      submission,
      filteredValidations,
      bloggerShopTask,
      instagramAttachments,
      userGeneratedContentAttachments,
      tiktokAttachment,
      manual
    } = this.props;

    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/submit`
    });

    let data = null
    if (contentType.name === 'InstagramStoryContent') {
      data = {
        type: contentType.name,
        id: bloggerShopTask.content ? bloggerShopTask.content.id : null,
        stories: selected || []
      };
    } else if(contentType.name === 'InstagramBusinessStoryContent'){
      data = {
        type: contentType.name,
        id: bloggerShopTask.content ? bloggerShopTask.content.id : null,
        stories: selected,
        instagram_business_story_media_ids: submission.instagram_business_story_media_ids
      };
    } else if(contentType.name == 'TiktokVideoContent'){
      data = {
        type: contentType.name,
        ...submission,
        ...preview,
        validations: filteredValidations,
        tiktok_svc_id: (selected || []).map(x => x.id).join(""),
      };
    } else if(contentType.name == 'InstagramReelContent'){
      data = {
        type: contentType.name,
        ...submission,
        ...preview,
        image_url: manual ? null : selected[0].image_url,
        validations: filteredValidations
      };
    }
    else {
      data = {
        type: contentType.name,
        ...submission,
        ...preview,
        draft: undefined,
        draft_attributes: submission.draft,
        validations: filteredValidations,
        instagram_attachment_ids: (instagramAttachments || []).map(x => x.id),
        user_generated_content_attachment_ids: (userGeneratedContentAttachments || []).map(x => x.id),
        tiktok_attachment_id: (tiktokAttachment && tiktokAttachment.id || undefined)
      };
    }

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: data,
    }).then((response) => {
      window.location.href = this.props.shoppertunityPath;
    }).catch(failure => {
      this.setState({submitting: false})
    });
  }

  displayRemoveBtn(){
    const {content} = this.props;
    return (content !== null && _.includes(['resubmitted', 'pending'], content.state));
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTask: state.bloggerShopTask,
      content: state.bloggerShopTask.content,
      contentUrl: state.contentUrl,
      submission: state.submission,
      validations: state.validations,
      proxyUrl: state.meta.proxyUrl,
      contentType: state.contentType,
      shoppertunityPath: state.meta.shoppertunityPath,
      readOnly: state.readOnly,
      selected: state.selected,
      manual: state.manual,
      filteredValidations: state.validations.map(v => ({
        round_task_requirement_id: v.id,
        verification_status: v.state
      })),
      preview: _.omitBy(state.preview, (v, k) => k == 'html' || k == 'type' || !v), // Filter out the huge HTML chunk in the preview, and any empty fields
      instagramAttachments: state.instagramAttachments,
      instagramMediaAttachments: state.instagramMediaAttachments || {},
      tiktokAttachment: state.tiktokAttachment,
      userGeneratedContentAttachments: state.userGeneratedContentAttachments,
      disableSubmit: state.disableSubmit
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setReadOnly: flag => dispatch({ type: 'SET_READ_ONLY', value: flag }),
      clearValidations: () => dispatch({ type: 'SET_VALIDATIONS', value: [] }),
      setContentType: type => dispatch({ type: 'SET_CONTENT_TYPE', value: type })
    };
  }
};

SubmissionSubmitButton.displayName = 'Submission.SubmitButton';
export default connect(
  SubmissionSubmitButton.mapStateToProps,
  SubmissionSubmitButton.mapDispatchToProps
)(SubmissionSubmitButton);
