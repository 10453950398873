class window.AdminPaymentIssues

  @index: ->

    load_payment_issues = ->
      DataTableUtils.datatable $('.datatable.payment_issues'),
        bServerSide: true
        aoColumnDefs: [
          { bVisible: false, aTargets: [ 0 ] }
        ]
        sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
        fnRowCallback: ( nRow, aData ) -> set_row_styles(nRow, aData)

    set_row_styles = (nRow, aData) ->
      if aData.includes('Reverted')
        nRow.className += ' reverted'
      else if aData.includes('Inactive')
        nRow.className += ' inactive'

    pTable =
      if $('.datatable.payment_issues').length then load_payment_issues()
